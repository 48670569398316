<template>
    <div class="box">
        <div id="selects">
            <!--      <b-select v-model="selectedSite" class="mb-2">
        <option :value="-1">{{ $t('global.all') }}</option>
        <option :value="site.id" v-for="site in sites">{{ site.ndd }}</option>
      </b-select>-->
            <div class="d-md-flex justify-content-around">
                <b-button :class="period === 'last7days' ? 'bg-warning' : 'bg-grey'" @click="changeDate('last7days')">
                    7 derniers jours
                </b-button>
                <b-button
                    :class="period === 'currentMonth' ? 'bg-warning' : 'bg-grey'"
                    @click="changeDate('currentMonth')"
                >
                    Mois en cours
                </b-button>
                <b-button :class="period === 'lastMonth' ? 'bg-warning' : 'bg-grey'" @click="changeDate('lastMonth')">
                    Mois dernier
                </b-button>
                <b-button
                    :class="period === 'currentYear' ? 'bg-warning' : 'bg-grey'"
                    @click="changeDate('currentYear')"
                >
                    Année en cours
                </b-button>
                <b-button :class="period === 'lastYear' ? 'bg-warning' : 'bg-grey'" @click="changeDate('lastYear')">
                    Année dernière
                </b-button>
                <b-button :class="period === 'custom' ? 'bg-warning' : 'bg-grey'" @click.prevent="openDateModal()">
                    Autres dates
                </b-button>
            </div>
        </div>
        <hr />
        <StatsDashboard :sites="sites" :stats="stats" :start_date="start_date" :end_date="end_date" :period="period" />
        <TopFlopSitesDashboard class="mt-2" :top="top_flop_sites.top" :flop="top_flop_sites.flop" />
        <TopFlopThemeDashboard class="mt-2" :top="top_flop_themes.top" :flop="top_flop_themes.flop" />
        <CustomDateModal ref="customDateModal" @changeDate="changeDate" />
        <SitesStatistics class="mt-2" :stats="sitesStats" />
    </div>
</template>

<script>
    import moment from 'moment'
    import StatsDashboard from '@/components/PartnerDashboard/StatsDashboard'
    import TopFlopSitesDashboard from '@/components/PartnerDashboard/TopFlopSitesDashboard.vue'
    import CustomDateModal from '@/components/PartnerDashboard/CustomDateModal.vue'
    import SitesStatistics from '@/components/PartnerDashboard/SitesStatistics.vue'
    import TopFlopThemeDashboard from '@/components/PartnerDashboard/TopFlopThemesDashboard.vue'
    import { mapActions, mapState } from 'vuex'

    export default {
        name: 'PartnerDashboard',
        title: 'layout.dashboard',
        components: {
            TopFlopThemeDashboard,
            SitesStatistics,
            CustomDateModal,
            TopFlopSitesDashboard,
            StatsDashboard
        },
        data() {
            return {
                sites: [],
                selectedSite: -1,
                start_date: null,
                end_date: null,
                top_flop_sites: {},
                top_flop_themes: {},
                sitesStats: null,
                stats: null,
                period: 'currentMonth'
            }
        },
        watch: {
            start_date: function () {
                this.getTopFlopSites()
                this.getTopFlopThemes()
                this.getStats()
            },
            site: function () {
                this.getStats()
            }
        },
        computed: {
            ...mapState('site_selector', {
                site: state => state.site
            })
        },
        created() {
            this.start_date = moment().startOf('month').format('YYYY-MM-DD')
            this.end_date = moment().format('YYYY-MM-DD')
            this.getAll()
            this.getSites()
            this.getTopFlopSites()
            this.getTopFlopThemes()
            this.getStats()
            this.getSitesStats()
        },
        methods: {
            ...mapActions('group', {
                getAll: 'getAll'
            }),
            getSites() {
                this.axios.get('/partner/dashboard/sites').then(response => {
                    this.sites = response.data
                })
            },
            getTopFlopSites() {
                this.axios
                    .get('/partner/dashboard/top-flop-sites', {
                        params: {
                            start_date: this.start_date,
                            end_date: this.end_date
                        }
                    })
                    .then(response => {
                        this.top_flop_sites = response.data
                    })
            },
            getTopFlopThemes() {
                this.axios
                    .get('/partner/dashboard/top-flop-themes', {
                        params: {
                            start_date: this.start_date,
                            end_date: this.end_date
                        }
                    })
                    .then(response => {
                        this.top_flop_themes = response.data
                    })
            },

            getStats() {
                this.axios
                    .get('/partner/dashboard/stats', {
                        params: {
                            start_date: this.start_date,
                            end_date: this.end_date,
                            site: this.site ? this.site.id : -1
                        }
                    })
                    .then(response => {
                        this.stats = response.data
                    })
            },
            getSitesStats() {
                this.axios.get('/partner/dashboard/sites-stats').then(response => {
                    this.sitesStats = response.data
                })
            },
            openDateModal() {
                this.$refs.customDateModal.show()
            },
            changeDate(type, start_date = null, end_date = null) {
                switch (type) {
                    case 'last7days':
                        this.start_date = moment().subtract(7, 'days').format('YYYY-MM-DD')
                        this.end_date = moment().format('YYYY-MM-DD')
                        this.period = 'last7days'
                        break
                    case 'currentMonth':
                        this.start_date = moment().startOf('month').format('YYYY-MM-DD')
                        this.end_date = moment().format('YYYY-MM-DD')
                        this.period = 'currentMonth'
                        break
                    case 'lastMonth':
                        this.start_date = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD')
                        this.end_date = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')
                        this.period = 'lastMonth'
                        break
                    case 'currentYear':
                        this.start_date = moment().startOf('year').format('YYYY-MM-DD')
                        this.end_date = moment().format('YYYY-MM-DD')
                        this.period = 'currentYear'
                        break
                    case 'lastYear':
                        this.start_date = moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD')
                        this.end_date = moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD')
                        this.period = 'lastYear'
                        break
                    case 'custom':
                        this.start_date = moment(start_date).format('YYYY-MM-DD')
                        this.end_date = moment(end_date).format('YYYY-MM-DD')
                        this.period = 'custom'
                        break
                }
                console.log(this.start_date, this.end_date)
            }
        }
    }
</script>

<style scoped>
    .bg-grey {
        background-color: #2b383f;
        color: white;
    }
</style>
